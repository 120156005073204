import './css/main.css';

import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { defineCustomElements } from 'vidstack/elements';

barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
        {
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    opacity: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        },
        {
            name: 'swipeFromRight-transition',
            from: {
                // define a custom rule based on the trigger class
                custom: ({ trigger }) => {
                  return trigger.classList && trigger.classList.contains('swipeFromRight');
                },
            },
            leave(data) {
                return gsap.to(data.current.container, {
                    x: '-100%'
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    x: '100%',
                    y: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        },
        {
            name: 'swipeFromLeft-transition',
            from: {
                // define a custom rule based on the trigger class
                custom: ({ trigger }) => {
                  return trigger.classList && trigger.classList.contains('swipeFromLeft');
                },
            },
            leave(data) {
                return gsap.to(data.current.container, {
                    x: '100%'
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    x: '-100%'
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        }
    ],
});

function galleryCheck() {
    let galleries = document.getElementsByClassName('gallery');
    if (galleries.length > 0) {
      import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
        const galleryGo = module.default;
        galleryGo();
    });
  };
};



const toggle = document.querySelector('#toggle'),
    sidebar = document.querySelector('#sideBar');

toggle.addEventListener('click', function(e) {
    e.preventDefault();
    if (sidebar.classList.contains('opacity-30')) {
        sidebar.classList.remove('opacity-30', 'translate-x-[100vw]');
        toggle.classList.add('rotate-[295deg]');
    } else {
        sidebar.classList.remove('opacity-100', '-translate-x-[0vw]');
        sidebar.classList.add('opacity-30', 'translate-x-[100vw]');
        toggle.classList.remove('rotate-[295deg]');
    }
});

document.querySelectorAll('.menuButton').forEach(button => {
    button.addEventListener('click', () => {
        sidebar.classList.remove('opacity-100', '-translate-x-[0vw]');
        sidebar.classList.add('opacity-30', 'translate-x-[100vw]');
        toggle.classList.remove('rotate-[295deg]');
    });
});






// ! Images hover up

// function hoverCheck() {
//     let hover = document.getElementsByClassName('hoverMe');
//     if (hover.length > 0) {
//       import(/* webpackChunkName: "hoverAnim" */ "./js/components/hoverAnim.js").then(module => {
//         const hovGo = module.default;
//         hovGo();
//     });
//   };
// };

function isoCheck() {
    let grid = document.getElementsByClassName('iso-grid');
    if (grid.length > 0) {
        import(/* webpackChunkName: "isoGrid" */ "./js/components/isoGrid.js").then(module => {
            const gridGo = module.default;
            gridGo();
        });
    }

    let gridPack = document.getElementsByClassName('iso-grid-packery');
    if (gridPack.length > 0) {
        import(/* webpackChunkName: "isoGridPack" */ "./js/components/isoGridPackery.js").then(module => {
            const gridPackGo = module.default;
            gridPackGo();
        });
    }
}

function moveItems() {
    let move = document.getElementsByClassName('moveMe');
    if (move.length > 0) {
        import(/* webpackChunkName: "moveMe" */ "./js/components/moveIt.js").then(module => {
            const moveIt = module.default;
            moveIt();
        });
    }
}

function hideHeader(data) {
        import(/* webpackChunkName: "hideHeader" */ "./js/components/hideHeader.js").then(module => {
            const hideHeader = module.default;
            hideHeader(data);
        });
}

function home2() {
    let bubblePage = document.getElementsByClassName('bubblePage');
    if (bubblePage.length > 0) {
        let bubbles = document.getElementsByClassName('bubble');
        let bb;
        for (bb=0; bb < bubbles.length; bb++) {
            let bub = bubbles[bb];
            
            let thehash = bub.getAttribute('data-hash');
            let trig = '#block-' + thehash;
            let end = '#endBlock-' + thehash;
            let thebub = document.getElementById('bubble-' + thehash);

            ScrollTrigger.create({
                trigger: trig,
                start: 'top center',
                end: 'bottom center',
                endTrigger: end,
                // onEnter: (self) => thebub.classList.remove('-translate-y-[120%]'),
                // onLeave: (self) => thebub.classList.add('-translate-y-[120%]'),
                onToggle: (self) => thebub.classList.toggle('-translate-y-[190%]'),
                // markers: {startColor: "white", endColor: "white", fontSize: "18px", fontWeight: "bold", indent: 20}
            })
        }
    }
}

function projectNav(data) {
    console.log('Data'+ data.next.container);
    let navStatus = data.next.container.getAttribute('data-projectnav');
    let bar = document.getElementById('projNavBar');
    if (navStatus != undefined && navStatus != null && navStatus == 'true') {
        import(/* webpackChunkName: "projectNav" */ "./js/components/projectNav.js").then(module => {
            const navGo = module.default;
            navGo(data);
        });
        
        bar.classList.remove('translate-y-full');
    } else {
        bar.classList.add('translate-y-full');
    }
}


function photCheck(data) {
    let photGrid = document.getElementsByClassName('photGrid');
    if (photGrid.length > 0) {
        import(/* webpackChunkName: "photoGrid" */ "./js/components/photoGrid.js").then(module => {
            const photoIt = module.default;
            photoIt(data)
        });
    }
}

function closeGallery() {
    let gal;
    let clickers = document.getElementsByClassName('closeGallery');
    if(clickers.length > 0) {
      for (gal=0; gal < clickers.length; gal++ ) {
        clickers[gal].addEventListener('click', function () {
          let gallery = document.getElementsByClassName('rollingGallery')[0];
          gallery.classList.add('hidden');
          // window.location.hash = '-';
          history.replaceState(null, null, ' ');
        })
      }
    }
  }


  function scrollToTop() {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    window.scrollTo({top: 0});
  };

  function removeContStyle(data) {
    setTimeout(function() {data.next.container.setAttribute('style', '')}, 1000);
  }


barba.hooks.once((data) => {

    galleryCheck();
    isoCheck();
    moveItems();
    hideHeader(data);
    // hoverCheck();
    // menuCheck();
    defineCustomElements();
    projectNav(data);
    closeGallery();
    photCheck(data);
});

barba.hooks.afterOnce((data) => {

    home2();
});

barba.hooks.beforeEnter((data) => {
    hideHeader(data);
    scrollToTop();
});


barba.hooks.after((data) => {

 galleryCheck();
 isoCheck();
 moveItems();
 home2();
//  hoverCheck();
//  menuCheck();
 defineCustomElements();
 projectNav(data);
 closeGallery();
 removeContStyle(data);
 photCheck(data);
});
